import {
  ActiveTransaction,
  MyEventTicketData,
  Place,
} from "@spwn/types/firebase/firestore";
import { useI18n } from "hooks/i18n/i18n";
import React, { FC, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { getDateTimeString, getEventDisplayInfo } from "utility";

import { AccountTicketItemHeading } from "./AccountTicketItemHeading";
import { AccountTicketItemTicketInfo } from "./AccountTicketItemTicketInfo";
import { getTicketStatusLabel } from "utility/event";
import { AccountTicketItemXShareButton } from "./AccountTicketItemXShareButton";
import { AccountTicketListHaveNotTicket } from "./AccountTicketListHaveNotTicket";
import { AccountTicketActionButton } from "./AccountTicketActionButton";

export const AccountTicketListInSession: FC<{
  eventMap: {
    [key: string]: Event;
  };
  myTickets: MyEventTicketData[];
  myUnprocessData: ActiveTransaction[];
  places: Place[] | undefined;
  onClickToggleConfirmCvsPayment: (transactionData: ActiveTransaction) => void;
}> = ({
  eventMap,
  myTickets,
  myUnprocessData,
  places,
  onClickToggleConfirmCvsPayment,
}) => {
  const { t } = useI18n();

  const inSessionMyTickets = useMemo(() => {
    const currentDatetime = new Date().getTime();
    return myTickets.length !== 0
      ? myTickets
          .filter((el) => {
            // イベントが終了している場合は表示しない
            if (el.event.isClosed) return false;
            // myTicketsには、チケットがないイベントも含まれている（Goodsなど）ため、チケットがないイベントは表示しない
            if (el.tickets.length === 0) return false;
            // event.partsがない場合は表示しない
            if (el.event.parts.length === 0) return false;

            const { eventStartTime } = el.event;
            if (eventStartTime === undefined) return false;

            /**
             * @description
             * イベントが開催中のものを抽出する処理
             *
             * イベントの開催日時は、event.partsに格納されている。
             * イベントの開催中の判定は、event.parts.openTimeの0:0:0 < 現在時刻 < event.parts.openTimeの23:59:59 で行なっている。
             * イベント終了の判定をevent.parts.openTimeの23:59:59で行なっている理由は、DBにイベント終了日時が格納されていないため。
             * event.partsは配列なので、filterした結果が空の場合は、開催中のイベントがないと判定する。
             */
            const inSessionEvent = el.event.parts.filter((part) => {
              const eventOpenDate = new Date(part.openTime.seconds * 1000);
              const eventOpenDatetime = new Date(
                eventOpenDate.getFullYear(),
                eventOpenDate.getMonth(),
                eventOpenDate.getDate(),
                0,
                0,
                0
              ).getTime();
              const eventEndDatetime = new Date(
                eventOpenDate.getFullYear(),
                eventOpenDate.getMonth(),
                eventOpenDate.getDate(),
                23,
                59,
                59
              ).getTime();

              return (
                eventOpenDatetime < currentDatetime &&
                currentDatetime < eventEndDatetime
              );
            });

            return inSessionEvent.length > 0;
          })
          .sort((a, b) => {
            const prev = Number(a.event.datetime.seconds);
            const cur = Number(b.event.datetime.seconds);
            return prev - cur;
          })
      : [];
  }, [myTickets]);

  if (inSessionMyTickets.length === 0) {
    return <AccountTicketListHaveNotTicket />;
  }

  return (
    <Box width={["90%", "740px"]} margin={"0 auto"}>
      {inSessionMyTickets.map((eventData, i) => {
        // @ts-expect-error TS2538
        const event = eventMap[eventData.event._id];
        const {
          belongedHostings,
          eventTitle,
          eventOpenDateFull,
          eventOpenDay,
          eventOpenTime,
          eventEndDateFull,
          isLongEvent,
          artists,
          eventId,
          isHide,
          isFesEvent,
        } = getEventDisplayInfo(event);

        const { twitterHashTag } = eventData.event;
        const unprocessData = myUnprocessData.filter(
          (el) => eventData.unprocessOrderIds.indexOf(String(el.orderId)) >= 0
        );

        const hasTicket = eventData.tickets.some(
          (product) => product.status === "PAYSUCCESS"
        );

        return (
          <Box key={i} marginBottom={["20px", "40px"]}>
            <Box
              padding={["16px 5% 32px", "20px 20px 40px"]}
              marginTop={"-6px"}
              bgcolor={"#fff"}
              style={{
                border: "1px solid #d4d4d4",
              }}
            >
              <AccountTicketItemHeading
                eventId={eventId}
                eventTitle={eventTitle}
                artists={artists}
                belongedHostings={belongedHostings}
                eventOpenDateFull={eventOpenDateFull}
                eventEndDateFull={eventEndDateFull}
                eventOpenDay={eventOpenDay}
                eventOpenTime={eventOpenTime}
                isLongEvent={isLongEvent}
              />

              {eventData.tickets.map((product, j) => {
                const productName = product.name;
                const price = product.price_jpy.toLocaleString();
                const count = product.count.toString();
                const place = places?.find((el) => product.place === el.code);

                const label = product.labelStatus
                  ? getTicketStatusLabel(product.labelStatus)
                  : "";
                const vodExpiredAt = getDateTimeString(
                  // @ts-expect-error TS2345
                  product.vodExpiredAt
                );
                return (
                  <Box key={j}>
                    <AccountTicketItemTicketInfo
                      ticketStateImageUrl={label}
                      place={place}
                      isFesEvent={isFesEvent}
                      productName={productName}
                      event={event}
                      vodExpiredAt={vodExpiredAt}
                      description={product.description}
                      price={price}
                      count={count}
                    />
                  </Box>
                );
              })}

              <Box
                width={["100%", "500px"]}
                margin={["32px auto 0", "40px auto 0"]}
              >
                {unprocessData.length !== 0 &&
                  unprocessData.map((transactionData, i) => {
                    const handleOnClickToggleConfirmCvsPayment = () => {
                      onClickToggleConfirmCvsPayment(transactionData);
                    };
                    return (
                      <Box marginBottom={"16px"} key={i}>
                        <AccountTicketActionButton
                          as={"button"}
                          variant={"caution"}
                          onClick={handleOnClickToggleConfirmCvsPayment}
                        >
                          {t("ticket.confirmSettlement")}
                        </AccountTicketActionButton>
                      </Box>
                    );
                  })}

                {hasTicket && (
                  <React.Fragment>
                    {/* フェスイベントはデジタルチケット未対応なのでイベントページに遷移させる */}
                    <Box marginBottom={"16px"}>
                      {isFesEvent ? (
                        <AccountTicketActionButton
                          as="link"
                          href={`/events/${eventId}`}
                          variant={"normal"}
                        >
                          {t("ticket.moveEventDetail")}
                        </AccountTicketActionButton>
                      ) : (
                        <AccountTicketActionButton
                          as="link"
                          href={`/account/ticket/${eventId}`}
                          variant={"normal"}
                        >
                          {t("ticket.viewDigitalTicket")}
                        </AccountTicketActionButton>
                      )}
                    </Box>

                    {!isHide && (
                      <AccountTicketItemXShareButton
                        eventId={eventId}
                        eventTitle={eventTitle}
                        twitterHashTag={twitterHashTag}
                      />
                    )}
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
