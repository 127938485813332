import firebase from "firebase/app";
import "firebase/auth";

const URI = process.env.REACT_APP_BACKEND_API_URL;

type Auth = firebase.auth.Auth;
// FIXME: main/packages/grpcを参考に型ファイルだけでも共有したい
type FetchConnectClient = {
  updateUser: (req: { name: string; email: string }) => Promise<{
    userId: string;
    userFirestoreId: string;
    name: string;
    email: string;
  }>;
};

export const createConnectClient: (
  auth: Auth | undefined
) => FetchConnectClient = (auth) => {
  const setHeader = async () => {
    const baseHeader = {
      "Content-Type": "application/json",
      "connect-protocol-version": "1",
    };
    if (auth === undefined) return baseHeader;
    const token = await auth.currentUser?.getIdToken();
    return {
      ...baseHeader,
      Authorization: "Bearer " + token,
    };
  };

  const fetchOption = async () => {
    const headers = await setHeader();
    return {
      method: "POST",
      headers: new Headers(headers),
    };
  };

  return {
    updateUser: async (req) => {
      const options = await fetchOption();

      const response = await fetch(
        URI + "/spwn.account.v1.PortalAccountService/UpdateUser",
        {
          ...options,
          body: JSON.stringify(req),
        }
      );
      return response.json();
    },
  };
};
