/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useI18n } from "hooks/i18n/i18n";
import React, { FC } from "react";
import { createXShareLink } from "utility/createXShareLink";
import { pushDataLayer } from "utility/ga";
import icon_Twitter from "../../designs/images/shareButton/icon_Twitter_400*409_bl.png";

export const AccountTicketItemXShareButton: FC<{
  eventId: string;
  eventTitle: string;
  twitterHashTag: string[];
}> = ({ eventId, eventTitle, twitterHashTag }) => {
  const { t } = useI18n();
  const countClicks = () => {
    pushDataLayer({
      event: "share",
      method: "Twitter",
      content_type: "event",
      item_id: eventId,
      eid: eventId,
    });
  };

  const twitterLink = createXShareLink({
    eventId,
    eventTitle,
    twitterHashTag,
  });

  return (
    <div
      onClick={() => {
        window.open(twitterLink, "_blank", "width=800,height=600");
        countClicks();
      }}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        padding: 15px 0;
        border: 2px solid #000;
        cursor: pointer;
      `}
    >
      <img
        src={icon_Twitter}
        alt="X"
        css={css`
          width: auto;
          height: 14px;
        `}
      />
      <p
        css={css`
          font-size: 14px;
          font-weight: bold;
        `}
      >
        {t("ticket.tweet")}
      </p>
    </div>
  );
};
