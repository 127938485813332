import Box from "@material-ui/core/Box";
import {
  ActiveTransaction,
  MyEventTicketData,
  Place,
} from "@spwn/types/firebase/firestore";
import { useI18n } from "hooks/i18n/i18n";
import React, { FC, useMemo } from "react";
import { getDateTimeString, getEventDisplayInfo } from "utility";
import { AccountTicketItemHeading } from "./AccountTicketItemHeading";
import { AccountTicketItemTicketInfo } from "./AccountTicketItemTicketInfo";
import { getDisplayPrice } from "utility/ticket";
import { AccountTicketItemGoodsInfo } from "./AccountTicketItemGoodsInfo";
import { AccountTicketListHaveNotTicket } from "./AccountTicketListHaveNotTicket";
import { getTicketStatusLabel } from "utility/event";
import { AccountTicketActionButton } from "./AccountTicketActionButton";
import { AccountTicketItemXShareButton } from "./AccountTicketItemXShareButton";

export const AccountTicketListArchiveEvent: FC<{
  eventMap: {
    [key: string]: Event;
  };
  myTickets: MyEventTicketData[];
  myUnprocessData: ActiveTransaction[];
  places: Place[] | undefined;
  onClickToggleConfirmCvsPayment: (transactionData: ActiveTransaction) => void;
}> = ({
  eventMap,
  myTickets,
  myUnprocessData,
  places,
  onClickToggleConfirmCvsPayment,
}) => {
  const { t } = useI18n();

  const inSessionMyTickets = useMemo(() => {
    const currentDatetime = new Date().getTime();
    return myTickets.length !== 0
      ? myTickets
          .filter((el) => {
            // イベントが終了している場合は表示しない
            if (el.event.isClosed) return false;
            // チケットがないイベントは表示しない
            if (el.tickets.length === 0) return false;
            // event.partsがない場合は表示しない
            if (el.event.parts.length === 0) return false;

            const inSessionEvent = el.event.parts.filter((part) => {
              const eventOpenDate = new Date(part.openTime.seconds * 1000);
              const eventOpenDatetime = new Date(
                eventOpenDate.getFullYear(),
                eventOpenDate.getMonth(),
                eventOpenDate.getDate(),
                0,
                0,
                0
              ).getTime();
              const eventEndDatetime = new Date(
                eventOpenDate.getFullYear(),
                eventOpenDate.getMonth(),
                eventOpenDate.getDate(),
                23,
                59,
                59
              ).getTime();

              // 現在日時がイベントの開催期間内にあるかをチェック
              return (
                eventOpenDatetime < currentDatetime &&
                currentDatetime < eventEndDatetime
              );
            });

            // 開催中のイベントが存在する場合のみ表示
            return inSessionEvent.length > 0;
          })
          .sort((a, b) => {
            const prev = Number(a.event.datetime.seconds);
            const cur = Number(b.event.datetime.seconds);
            return prev - cur;
          })
      : [];
  }, [myTickets]);

  /**
   * 将来的にこのコンポーネントにfetch処理が入るので、以下のfilter処理はここに置いておく。
   */
  const closedMyTickets = useMemo(() => {
    const now = Date.now() / 1000;

    return myTickets.length !== 0
      ? myTickets
          .filter((el) => {
            const eventTime = Number(el.event.datetime.seconds);
            // イベントが終了しているか、現在日時がイベントの日時を過ぎているか
            const isEventEnded = el.event.isClosed === true || eventTime < now;
            // inSessionMyTickets に含まれているイベントを排除
            const isInSession = inSessionMyTickets.some(
              (sessionTicket) => sessionTicket.event._id === el.event._id
            );

            return isEventEnded && !isInSession;
          })
          .sort((a, b) => {
            const prev = Number(a.event.datetime.seconds);
            const cur = Number(b.event.datetime.seconds);
            return cur - prev; // 終了した日時が新しい順にソート
          })
      : [];
  }, [myTickets, inSessionMyTickets]);

  if (closedMyTickets.length === 0) {
    return <AccountTicketListHaveNotTicket />;
  }

  return (
    <Box width={["90%", "740px"]} margin={"0 auto"}>
      {closedMyTickets.map((eventData, i) => {
        // @ts-expect-error TS2538
        const event = eventMap[eventData.event._id];
        const {
          belongedHostings,
          eventTitle,
          eventOpenDateFull,
          eventOpenDay,
          eventOpenTime,
          eventEndDateFull,
          isLongEvent,
          artists,
          eventId,
          isFesEvent,
          isHide,
        } = getEventDisplayInfo(event);

        const unprocessData = myUnprocessData.filter(
          (el) => eventData.unprocessOrderIds.indexOf(String(el.orderId)) >= 0
        );
        const { twitterHashTag } = eventData.event;

        return (
          <Box key={i} marginBottom={["20px", "40px"]}>
            <Box
              padding={["16px 5% 32px", "20px 20px 40px"]}
              marginTop={"-6px"}
              bgcolor={"#fff"}
              style={{
                border: "1px solid #d4d4d4",
              }}
            >
              <AccountTicketItemHeading
                eventId={eventId}
                eventTitle={eventTitle}
                artists={artists}
                belongedHostings={belongedHostings}
                eventOpenDateFull={eventOpenDateFull}
                eventEndDateFull={eventEndDateFull}
                eventOpenDay={eventOpenDay}
                eventOpenTime={eventOpenTime}
                isLongEvent={isLongEvent}
              />

              {eventData.tickets.map((product, j) => {
                const productName = product.name;
                const price = product.price_jpy.toLocaleString();
                const count = product.count.toString();
                const place = places?.find((el) => product.place === el.code);

                const label = product.labelStatus
                  ? getTicketStatusLabel(product.labelStatus)
                  : "";
                const vodExpiredAt = getDateTimeString(
                  // @ts-expect-error TS2345
                  product.vodExpiredAt
                );
                return (
                  <Box key={j}>
                    <AccountTicketItemTicketInfo
                      ticketStateImageUrl={label}
                      place={place}
                      isFesEvent={isFesEvent}
                      productName={productName}
                      event={event}
                      vodExpiredAt={vodExpiredAt}
                      description={product.description}
                      price={price}
                      count={count}
                    />
                  </Box>
                );
              })}

              {eventData.goods.map((product, j) => {
                const productName = product.name;
                const price = getDisplayPrice(product).toLocaleString();
                const count = product.count.toString();
                const place = places?.find((el) => product.place === el.code);
                const subClassName =
                  product.subClassName && `（${product.subClassName}）`;
                return (
                  <Box key={j}>
                    <AccountTicketItemGoodsInfo
                      productName={productName}
                      subClassName={subClassName}
                      price={price}
                      count={count}
                      place={place}
                    />
                  </Box>
                );
              })}

              <Box
                width={["100%", "500px"]}
                margin={["32px auto 0", "40px auto 0"]}
              >
                {unprocessData.length !== 0 &&
                  unprocessData.map((transactionData, i) => {
                    const handleOnClickToggleConfirmCvsPayment = () => {
                      onClickToggleConfirmCvsPayment(transactionData);
                    };
                    return (
                      <Box marginBottom={"16px"} key={i}>
                        <AccountTicketActionButton
                          as="button"
                          variant={"caution"}
                          onClick={handleOnClickToggleConfirmCvsPayment}
                        >
                          {t("ticket.confirmSettlement")}
                        </AccountTicketActionButton>
                      </Box>
                    );
                  })}

                {/* フェスイベントはデジタルチケット未対応なのでイベントページに遷移させる */}
                <Box>
                  {isFesEvent ? (
                    <AccountTicketActionButton
                      as="link"
                      href={`/events/${eventId}`}
                      variant={"normal"}
                    >
                      {t("ticket.moveEventDetail")}
                    </AccountTicketActionButton>
                  ) : (
                    <AccountTicketActionButton
                      as="link"
                      href={`/account/ticket/${eventId}`}
                      variant={"normal"}
                    >
                      {t("ticket.viewDigitalTicket")}
                    </AccountTicketActionButton>
                  )}
                </Box>

                {!isHide && (
                  <Box marginTop={"16px"}>
                    <AccountTicketItemXShareButton
                      eventId={eventId}
                      eventTitle={eventTitle}
                      twitterHashTag={twitterHashTag}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
