import ButtonBase from "@material-ui/core/ButtonBase";
import { Link } from "react-router-dom";
import React, { FC } from "react";

const buttonCommonStyle: (
  variant: "normal" | "caution"
) => React.CSSProperties = (variant) => {
  const bgColor = () => {
    switch (variant) {
      case "normal":
        return "#00c2ae";
      case "caution":
        return "#ff0000";
      //TODO: あとでdisableなども追加する予定
    }
  };
  return {
    display: "inline-block",
    width: "100%",
    height: "48px",
    padding: "14px",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: "1px",
    backgroundColor: bgColor(),
    color: "#fff",
  };
};

type AccountTicketActionButtonProps =
  | {
      as: "button";
      children: string;
      variant: "normal" | "caution";
      onClick: () => void;
      href?: never;
    }
  | {
      as: "link";
      children: string;
      variant: "normal" | "caution";
      href: string;
      onClick?: never;
    };

export const AccountTicketActionButton: FC<AccountTicketActionButtonProps> = ({
  as,
  children,
  href,
  variant,
  onClick,
}) => {
  switch (as) {
    case "button":
      return (
        <ButtonBase onClick={onClick} style={buttonCommonStyle(variant)}>
          {children}
        </ButtonBase>
      );
    case "link":
      return (
        <Link to={href} style={buttonCommonStyle(variant)}>
          {children}
        </Link>
      );
  }
};
